import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utility';

const initialState = {
	data: false,
	configToken: null,
	loading: false,
	error: false,
};

const authConfigSuccess = (state, action) => {
	return updateObject(state, {
		configToken: action.token,
		error: false,
		loading: false,
	});
};

const authConfigLogout = (state) => {
	return updateObject(state, {
		data: false,
		configToken: null,
		loading: false,
		error: false,
	});
};

const configObtenerLoading = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const configObtenerSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		error: false,
		loading: false,
	});
};

const configObtenerFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.CONFIG_AUTH_SUCCESS:
			return authConfigSuccess(state, action);
		case actionTypes.CONFIG_AUTH_LOGOUT:
			return authConfigLogout(state);
		case actionTypes.CONFIG_OBTENER_LOADING:
			return configObtenerLoading(state, action);
		case actionTypes.CONFIG_OBTENER_FAIL:
			return configObtenerFail(state, action);
		case actionTypes.CONFIG_OBTENER_SUCCESS:
			return configObtenerSuccess(state, action);
		default:
			return state;
	}
};


export default reducer;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Container, Modal } from 'reactstrap';

import List from '../components/List';
import ModalSaveEdit from '../components/modals/ModalSaveEdit.jsx';
import PaginationComponent from '../components/Pagination';
import Search from '../components/Search.jsx';

import { getMobilesListado, saveMobileItem, editarMobileItem } from '../store/actions/mobiles.actions'

const Mobiles = () => {
	const { configToken } = useSelector(({ other }) => other);
	const { loading, data, page, totalPages } = useSelector(({ mobiles }) => mobiles);
	const dispatch = useDispatch();

	const [showSave, setShowSave] = useState(false);
	const [filter, setFilter] = useState({});
	const handleToggle = () => setShowSave(!showSave);
	const handleSaveItem = (item) => {
		setShowSave(true);
	}

	useEffect(() => {
		dispatch(getMobilesListado(1, filter))
	}, [])

	const handleClick = (page) => {
		dispatch(getMobilesListado(page, filter))
	}

	const handleEdit = (form) => {
		dispatch(editarMobileItem(form, filter))
	}
	const handleSave = (form) => {
		dispatch(saveMobileItem(form))
	}

	const handleSearch = (filterSearch) => {
		setFilter(filterSearch);
		dispatch(getMobilesListado(1, filterSearch))
	}

	const headers = [
		{ 'key': '_id', 'label': "ID", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': [] },
		{ 'key': 'ruc', 'label': "RUC", 'visible': ['list', 'form'] },
		{ 'key': 'name', 'label': "Nombre", 'visible': ['list', 'form'] },
		{ 'key': 'url', 'label': "URL", 'visible': ['list', 'form'] },
		{ 'key': 'db', 'label': "DB", 'visible': ['list', 'form'] },
		{ 'key': 'port', 'label': "Puerto", 'visible': ['list', 'form'] },
	]
	const searchItems = [
		{ 'key': 'ruc', 'label': 'RUC' },
		{ 'key': 'name', 'label': 'Nombre' },
	]

	return (
		<div>
		{configToken
			?
			<Container>
				<Search filter={filter} searchItems={searchItems} handleSearch={handleSearch} />
				<br />
				<Button onClick={handleSaveItem}>Crear</Button>
				<Modal isOpen={showSave} toggle={handleToggle}>
					<ModalSaveEdit show={showSave} toggle={handleToggle} headers={headers} handleSaveEdit={handleSave} isEdit={false} />
				</Modal>
				{loading && <span>Cargando...</span>}
				<List headers={headers} items={data} handleSaveEdit={handleEdit} />
				<PaginationComponent handleClick={handleClick} page={page} totalPages={totalPages} />
			</Container>
			:
			<span>No existe la conexión</span>
		}
		</div>
	);
};

export default Mobiles;
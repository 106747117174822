import axios from '../../utils/axios';
import {
	PEDIDOS_LISTADO_LOADING,
	PEDIDOS_LISTADO_FAIL,
	PEDIDOS_LISTADO_SUCCESS,
	PEDIDO_CREAR_FAIL,
	PEDIDO_CREAR_LOADING,
	PEDIDO_CREAR_SUCCESS,
	PEDIDO_EDITAR_FAIL,
	PEDIDO_EDITAR_LOADING,
	PEDIDO_EDITAR_SUCCESS,
	PEDIDOS_REPORTE_FAIL,
	PEDIDOS_REPORTE_LOADING,
	PEDIDOS_REPORTE_SUCCESS,
} from './actionTypes';

export const pedidosListadoLoading = () => {
	return {
		type: PEDIDOS_LISTADO_LOADING,
	};
};
export const pedidosListadoFail = () => {
	return {
		type: PEDIDOS_LISTADO_FAIL,
	};
};
export const pedidosListadoSuccess = (data) => {
	return {
		type: PEDIDOS_LISTADO_SUCCESS,
		...data,
	};
};

export const getPedidosListado = (page = 1, filter = {}) => {
	return async (dispatch) => {
		dispatch(pedidosListadoLoading());
		try {
			const { data } = await axios.post('api/pedidos/search', { page, filter });
			dispatch(pedidosListadoSuccess(data));
		} catch (e) {
			dispatch(pedidosListadoFail());
		}
	};
};

export const pedidoCrearLoading = () => {
	return {
		type: PEDIDO_CREAR_LOADING,
	};
};
export const pedidoCrearFail = () => {
	return {
		type: PEDIDO_CREAR_FAIL,
	};
};
export const pedidoCrearSuccess = (data) => {
	return {
		type: PEDIDO_CREAR_SUCCESS,
		...data,
	};
};

export const guardarPedidoItem = (data) => {
	return async (dispatch) => {
		dispatch(pedidoCrearLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.post('api/pedidos', data_send);
			dispatch(pedidoCrearSuccess(response));
			dispatch(getPedidosListado(1));
		} catch (e) {
			dispatch(pedidoCrearFail());
		}
	};
};

export const pedidoEditarLoading = () => {
	return {
		type: PEDIDO_EDITAR_LOADING,
	};
};
export const pedidoEditarFail = () => {
	return {
		type: PEDIDO_EDITAR_FAIL,
	};
};
export const pedidoEditarSuccess = (data) => {
	return {
		type: PEDIDO_EDITAR_SUCCESS,
		...data,
	};
};

export const editarPedidoItem = (data, filter) => {
	return async (dispatch, getState) => {
		dispatch(pedidoEditarLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.put('api/pedidos', {
				_id: _id,
				data: data_send,
			});
			dispatch(pedidoEditarSuccess(response));
			dispatch(getPedidosListado(getState().pedidos.page, filter));
		} catch (e) {
			dispatch(pedidoEditarLoading());
		}
	};
};

export const rescatarPedidoItem = (data, filter, cb) => {
	return async (dispatch, getState) => {
		dispatch(pedidoEditarLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.post(`api/pedidos/recover/${_id}`);
			dispatch(pedidoEditarSuccess(response));
			cb();
			dispatch(getPedidosListado(getState().pedidos.page, filter));
		} catch (e) {
			cb();
			dispatch(pedidoEditarFail());
		}
	};
};

export const pedidosReporteLoading = () => {
	return {
		type: PEDIDOS_REPORTE_LOADING,
	};
};
export const pedidosReporteFail = () => {
	return {
		type: PEDIDOS_REPORTE_FAIL,
	};
};
export const pedidosReporteSuccess = () => {
	return {
		type: PEDIDOS_REPORTE_SUCCESS,
	};
};

export const reportePedidos = (data_send) => {
	return async (dispatch) => {
		dispatch(pedidosReporteLoading());
		axios({
			method: 'post',
			url: 'api/pedidos_report',
			responseType: 'blob',
			data: data_send,
		})
			.then((response) => {
				const fileNameHeader = 'x-suggested-filename';
				const suggestedFileName = response.headers[fileNameHeader];
				let effectiveFileName =
					suggestedFileName === undefined
						? 'Reporte de pedidos.xlsx'
						: suggestedFileName;
				effectiveFileName =
					effectiveFileName.substr(0, effectiveFileName.length - 4) + '.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', effectiveFileName);
				document.body.appendChild(link);
				link.click();
				dispatch(pedidosReporteSuccess());
			})
			.catch(async (error) => {
				dispatch(pedidosReporteFail());
			});
	};
};

import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { authCheckState } from '../store/actions/auth.actions';
import { authConfigCheckState } from '../store/actions/other.actions';

import Layout from '../components/Layout';
import Pedidos from '../containers/Pedidos';
import DBs from '../containers/DBs';
import Anulados from '../containers/Anulados';
import Config from '../components/Config';
import Mobiles from '../containers/Mobiles';
import LoginGoogle from '../components/LoginGoogle';

const App = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(authCheckState());
		dispatch(authConfigCheckState());
		// eslint-disable-next-line
	}, []);
	return (
		<BrowserRouter>
			<Layout>
				<Switch>
					<Route exact path="/login" component={LoginGoogle} />
					<Route exact path="/pedidos" component={Pedidos} />
					<Route exact path="/anulados" component={Anulados} />
					<Route exact path="/dbs" component={DBs} />
					<Route exact path="/mobiles" component={Mobiles} />
					<Route exact path="/config" component={Config} />
					<Route component={Pedidos} />
				</Switch>
			</Layout>
		</BrowserRouter>
	)
};

export default App;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Button, Form, Label, Input, Col, Row } from 'reactstrap';

import { guardarConfiguracion, getConfigData } from '../store/actions/other.actions'

const Config = () => {
	const { loading, data, error, configToken } = useSelector(({ other }) => other);
	const dispatch = useDispatch();
	const initialForm = data;
	const [form, setForm] = useState(initialForm);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		dispatch(getConfigData());
	}, [])

	if (!loaded && data) {
		setForm(data);
		setLoaded(true);
	}

	const handleSubmit = event => {
		event.preventDefault();
		dispatch(guardarConfiguracion(form))
	};

	const handleInput = event => {
		var evalue = event.target.value;
		if (event.target.name.includes("_aminutes") || event.target.name.includes("_ahours")){
			evalue = evalue.replace(/ /g,'')
		}
		setForm({
			...form,
			[event.target.name]: evalue,
		});
	};

	const handleChecked = event => {
		setForm({
			...form,
			[event.target.name]: event.target.checked
		});
	};

	return (
		<div>
		{configToken
			?
			<Container>
				<Form>
					<Row>
						<Label check sm={6}>
							<Input type="checkbox" name="send_invoices" onChange={handleChecked} defaultChecked={form.send_invoices} />{' '}
							Envios Facturas SUNAT
						</Label>
					</Row>
					<Row>
						<Label check sm={6}>
							<Input type="checkbox" name="send_invoices_ose" onChange={handleChecked} defaultChecked={form.send_invoices_ose} />{' '}
							Envios Facturas OSE
						</Label>
					</Row>
					<Row>
						<Label check sm={2} >
							<Input type="checkbox" name="send_summaries" onChange={handleChecked} defaultChecked={form.send_summaries} />{' '}
							Envios Boletas SUNAT
						</Label>
						<Col sm={4} >
							<Input name="summaries_ahours" bssize="sm" onChange={handleInput} value={form.summaries_ahours} />
							<p bssize="sm" className="text-muted">Horas</p>
						</Col>
						<Col sm={4} >
							<Input name="summaries_aminutes" bssize="sm" onChange={handleInput} value={form.summaries_aminutes} />
							<p bssize="sm" className="text-muted">Minutos</p>
						</Col>
					</Row>
					<Row>
						<Label check sm={2}>
							<Input type="checkbox" name="send_summaries_ose" onChange={handleChecked} defaultChecked={form.send_summaries_ose} />{' '}
							Envios Boletas OSE
						</Label>
						<Col sm={4} >
							<Input name="summaries_ose_ahours" bssize="sm" onChange={handleInput} value={form.summaries_ose_ahours} />
							<p bssize="sm" className="text-muted">Horas</p>
						</Col>
						<Col sm={4} >
							<Input name="summaries_ose_aminutes" bssize="sm" onChange={handleInput} value={form.summaries_ose_aminutes} />
							<p bssize="sm" className="text-muted">Minutos</p>
						</Col>
					</Row>
					<Row>
						<Label check sm={2}>
							<Input type="checkbox" name="send_void" onChange={handleChecked} defaultChecked={form.send_void} />{' '}
							Bajas SUNAT
						</Label>
						<Col sm={4} >
							<Input name="summary_void_ahours" bssize="sm" onChange={handleInput} value={form.summary_void_ahours} />
							<p bssize="sm" className="text-muted">Horas</p>
						</Col>
						<Col sm={4} >
							<Input name="summary_void_aminutes" bssize="sm" onChange={handleInput} value={form.summary_void_aminutes} />
							<p bssize="sm" className="text-muted">Minutos</p>
						</Col>
					</Row>
					<Row>
						<Label check sm={2}>
							<Input type="checkbox" name="send_void_ose" onChange={handleChecked} defaultChecked={form.send_void_ose} />{' '}
							Bajas OSE
						</Label>
						<Col sm={4} >
							<Input name="summary_void_ose_ahours" bssize="sm" onChange={handleInput} value={form.summary_void_ose_ahours} />
							<p bssize="sm" className="text-muted">Horas</p>
						</Col>
						<Col sm={4} >
							<Input name="summary_void_ose_aminutes" bssize="sm" onChange={handleInput} value={form.summary_void_ose_aminutes} />
							<p bssize="sm" className="text-muted">Minutos</p>
						</Col>
					</Row>
					<Row>
						<Label check sm={6}>
							<Input type="checkbox" name="check_invoices" onChange={handleChecked} defaultChecked={form.check_invoices} />{' '}
							Check invoices
						</Label>
					</Row>
					<Row>
						<Label check sm={6}>
							<Input type="checkbox" name="guide_sunat" onChange={handleChecked} defaultChecked={form.guide_sunat} />{' '}
							Envio guias SUNAT
						</Label>
					</Row>
					<Row>
						<Label check sm={6}>
							<Input type="checkbox" name="guide_ose" onChange={handleChecked} defaultChecked={form.guide_ose} />{' '}
							Envio guias OSE
						</Label>
					</Row>
					<Row>
						<Label sm={2}>Tickets</Label>
						<Col sm={4} >
							<Input name="summary_tickets_ahours" bssize="sm" onChange={handleInput} value={form.summary_tickets_ahours} />
							<p bssize="sm" className="text-muted">Horas</p>
						</Col>
						<Col sm={4} >
							<Input name="summary_tickets_aminutes" bssize="sm" onChange={handleInput} value={form.summary_tickets_aminutes} />
							<p bssize="sm" className="text-muted">Minutos</p>
						</Col>
					</Row>
					<Button color="primary" onClick={handleSubmit}>Guardar</Button>
				</Form>
			</Container>
			:
			<span>No existe la conexión</span>
		}
		</div>
	);

};

export default Config;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Container, Modal } from 'reactstrap';

import List from '../components/List';
import ModalSaveEdit from '../components/modals/ModalSaveEdit.jsx';
import PaginationComponent from '../components/Pagination';
import Search from '../components/Search.jsx';

import { getDBsListado, saveDBItem, editarDBItem, eliminarDBItem } from '../store/actions/dbs.actions';

const DBs = () => {
	const { loading, data, page, totalPages } = useSelector(({ dbs }) => dbs);
	const dispatch = useDispatch();

	const [showSave, setShowSave] = useState(false);
	const [filter, setFilter] = useState({});
	const handleToggle = () => setShowSave(!showSave);
	const handleSaveItem = (item) => {
		setShowSave(true);
	};

	useEffect(() => {
		dispatch(getDBsListado(1, filter));
	}, []);

	const handleClick = (page) => {
		dispatch(getDBsListado(page, filter));
	};

	const handleEdit = (form) => {
		dispatch(editarDBItem(form, filter));
	};
	const handleSave = (form) => {
		dispatch(saveDBItem(form));
	};
	/*const handleDelete = (id) => {
		dispatch(eliminarDBItem(id))
	}*/

	const handleSearch = (filterSearch) => {
		setFilter(filterSearch);
		dispatch(getDBsListado(1, filterSearch));
	};

	const headers = [
		{ key: '_id', label: 'ID', inputSettingsEdit: { type: 'text', readOnly: true }, visible: [] },
		{ key: 'name', label: 'RUC', inputSettingsEdit: { type: 'text', readOnly: true }, visible: ['list', 'form'] },
		{ key: 'db', label: 'DB', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['list', 'form'] },
		{ key: 'url', label: 'URL Odoo', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['form'] },
		{ key: 'url_db', label: 'URL DB', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['form'] },
		{ key: 'port', label: 'Puerto', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['form'] },
		{ key: 'user', label: 'Usuario', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['form'] },
		{ key: 'pwd', label: 'Contraseña', inputSettingsEdit: { type: 'text', readOnly: false }, visible: ['form'] },
		{
			key: 'port_odoo',
			label: 'Puerto Odoo',
			inputSettingsEdit: { type: 'text', readOnly: false },
			visible: ['form']
		}
	];
	const searchItems = [
		{ key: 'name', label: 'RUC' },
		{ key: 'db', label: 'DB' }
	];

	return (
		<Container>
			<Search filter={filter} searchItems={searchItems} handleSearch={handleSearch} />
			<br />
			<Button onClick={handleSaveItem}>Crear</Button>
			<Modal isOpen={showSave} toggle={handleToggle}>
				<ModalSaveEdit
					show={showSave}
					toggle={handleToggle}
					headers={headers}
					handleSaveEdit={handleSave}
					isEdit={false}
				/>
			</Modal>
			{loading && <span>Cargando...</span>}
			<List headers={headers} items={data} handleSaveEdit={handleEdit} />
			<PaginationComponent handleClick={handleClick} page={page} totalPages={totalPages} />
		</Container>
	);
};

export default DBs;

import axios from '../../utils/axios';
import {
	ANULADOS_LISTADO_LOADING, ANULADOS_LISTADO_FAIL, ANULADOS_LISTADO_SUCCESS,
	ANULADO_CREAR_LOADING, ANULADO_CREAR_FAIL, ANULADO_CREAR_SUCCESS,
	ANULADO_EDITAR_LOADING, ANULADO_EDITAR_FAIL, ANULADO_EDITAR_SUCCESS,
	ANULADO_ELIMINAR_LOADING, ANULADO_ELIMINAR_FAIL, ANULADO_ELIMINAR_SUCCESS,
} from './actionTypes';

export const anuladosListadoLoading = () => {
	return {
		type: ANULADOS_LISTADO_LOADING,
	};
};
export const anuladosListadoFail = () => {
	return {
		type: ANULADOS_LISTADO_FAIL,
	};
};
export const anuladosListadoSuccess = (data) => {
	return {
		type: ANULADOS_LISTADO_SUCCESS,
		...data
	};
};

export const getAnuladosListado = (page = 1, filter = {}) => {
	return async (dispatch) => {
		dispatch(anuladosListadoLoading());
		try {
			const { data } = await axios.post('api/anulados/search', { page, filter });
			dispatch(anuladosListadoSuccess(data))
		} catch (e) {
			dispatch(anuladosListadoFail());
		}
	};
};


export const anuladoCrearLoading = () => {
	return {
		type: ANULADO_CREAR_LOADING,
	};
};
export const anuladoCrearFail = () => {
	return {
		type: ANULADO_CREAR_FAIL,
	};
};
export const anuladoCrearSuccess = (data) => {
	return {
		type: ANULADO_CREAR_SUCCESS,
		...data
	};
};

export const crearAnuladoItem = (data) => {
	return async (dispatch) => {
		dispatch(anuladoCrearLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.post('api/anulado', data_send);
			dispatch(anuladoCrearSuccess(data))
			dispatch(getAnuladosListado(1))
		} catch (e) {
			dispatch(anuladoCrearFail());
		}
	};
}


export const anuladoEditarLoading = () => {
	return {
		type: ANULADO_EDITAR_LOADING,
	};
};
export const anuladoEditarFail = () => {
	return {
		type: ANULADO_EDITAR_FAIL,
	};
};
export const anuladoEditarSuccess = (data) => {
	return {
		type: ANULADO_EDITAR_SUCCESS,
		...data
	};
};

export const editarAnuladoItem = (data, filter) => {
	return async (dispatch, getState) => {
		dispatch(anuladoEditarLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.put('api/anulado', { '_id': _id, 'data': data_send });
			dispatch(anuladoEditarSuccess(data))
			dispatch(getAnuladosListado(getState().anulados.page, filter))
		} catch (e) {
			dispatch(anuladoEditarFail());
		}
	};
}


export const anuladoEliminarLoading = () => {
	return {
		type: ANULADO_ELIMINAR_LOADING,
	};
};
export const anuladoEliminarFail = () => {
	return {
		type: ANULADO_ELIMINAR_FAIL,
	};
};
export const anuladoEliminarSuccess = () => {
	return {
		type: ANULADO_ELIMINAR_SUCCESS,
	};
};

export const eliminarAnuladoItem = (_id) => {
	return async (dispatch, getState) => {
		dispatch(anuladoEliminarLoading());
		try {
			//const response = await axios.delete('api/anulado', { _id, token });
			dispatch(anuladoEliminarSuccess())
			dispatch(getAnuladosListado(getState().anulados.page))
		} catch (e) {
			dispatch(anuladoEliminarFail());
		}
	};
}
//import axios from 'axios';
import axios from '../../utils/axios_ruc';

import {
	CONFIG_AUTH_START, CONFIG_AUTH_SUCCESS, CONFIG_AUTH_FAIL, CONFIG_AUTH_LOGOUT,
	CONFIG_GUARDAR_LOADING,
	CONFIG_GUARDAR_FAIL,
	CONFIG_GUARDAR_SUCCESS,
	CONFIG_OBTENER_LOADING,
	CONFIG_OBTENER_FAIL,
	CONFIG_OBTENER_SUCCESS,
} from './actionTypes';

export const authConfigCheckState = () => {
	return (dispatch) => {
		dispatch(authConfigStart());
		const token = localStorage.getItem('tokenConfig');
		if (!token) {
			dispatch(logoutConfig());
		} else {
			axios({
				url: 'api/config/auth/verify',
				method: 'POST',
				data: { token }
			})
				.then((response) => {
					dispatch(
						authConfigSuccessAction({
							token,
							...response.data,
						})
					);
				})
				.catch((err) => {
					dispatch(authConfigFail(err));
				});
		}
	};
};

export const logoutConfig = () => {
	return {
		type: CONFIG_AUTH_LOGOUT,
	};
};
export const logoutConfigAction = () => {
	return async (dispatch) => {
		localStorage.removeItem('tokenConfig');
		axios.defaults.headers.common['Authorization'] = null;
		axios.defaults.headers.common.Authorization = null;
		dispatch(logoutConfig());
	}
};
export const authConfigStart = () => {
	return {
		type: CONFIG_AUTH_START,
	};
};
export const authConfigFail = () => {
	return {
		type: CONFIG_AUTH_FAIL,
	};
};
export const authConfigSuccess = (data) => {
	return {
		type: CONFIG_AUTH_SUCCESS,
		...data
	};
};
export const authConfigSuccessAction = (data) => {
	return async (dispatch) => {
		localStorage.setItem('tokenConfig', data.token);
		axios.defaults.headers.common['Authorization'] = data.token;
		axios.defaults.headers.common.Authorization = data.token;
		dispatch(authConfigSuccess(data))
	}
};

export const getConfigToken = (user) => {
	return async (dispatch) => {
		dispatch(authConfigStart());
		try {
			const { data } = await axios.post('api/config/token', user);
			dispatch(authConfigSuccessAction(data))
		} catch (e) {
			dispatch(authConfigFail());
		}
	};
};


export const configObtenerLoading = () => {
	return {
		type: CONFIG_OBTENER_LOADING,
	};
};
export const configObtenerFail = () => {
	return {
		type: CONFIG_OBTENER_FAIL,
	};
};
export const configObtenerSuccess = (data) => {
	return {
		type: CONFIG_OBTENER_SUCCESS,
		...data,
	};
};
export const getConfigData = () => {
	return async (dispatch) => {
		dispatch(configObtenerLoading());
		try {
			const { data } = await axios.get('api/config');
			dispatch(configObtenerSuccess({data: data}));
		} catch (e) {
			dispatch(configObtenerFail());
		}
	};
};

export const configGuardarLoading = () => {
	return {
		type: CONFIG_GUARDAR_LOADING,
	};
};
export const configGuardarFail = () => {
	return {
		type: CONFIG_GUARDAR_FAIL,
	};
};
export const configGuardarSuccess = (data) => {
	return {
		type: CONFIG_GUARDAR_SUCCESS,
		...data,
	};
};

export const guardarConfiguracion = (data) => {
	return async (dispatch) => {
		dispatch(configGuardarLoading());
		try {
			const response = await axios.post('api/config', data);
			dispatch(configGuardarSuccess(response));
			dispatch(getConfigData());
		} catch (e) {
			dispatch(configGuardarFail());
		}
	};
};

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//CONFIGURACION
export const CONFIG_AUTH_START = 'CONFIG_AUTH_START';
export const CONFIG_AUTH_SUCCESS = 'CONFIG_AUTH_SUCCESS';
export const CONFIG_AUTH_FAIL = 'CONFIG_AUTH_FAIL';
export const CONFIG_AUTH_LOGOUT = 'CONFIG_AUTH_LOGOUT';

export const CONFIG_OBTENER_LOADING = 'CONFIG_OBTENER_LOADING';
export const CONFIG_OBTENER_FAIL = 'CONFIG_OBTENER_FAIL';
export const CONFIG_OBTENER_SUCCESS = 'CONFIG_OBTENER_SUCCESS';

export const CONFIG_GUARDAR_LOADING = 'CONFIG_GUARDAR_LOADING';
export const CONFIG_GUARDAR_FAIL = 'CONFIG_GUARDAR_FAIL';
export const CONFIG_GUARDAR_SUCCESS = 'CONFIG_GUARDAR_SUCCESS';

//PEDIDOS
export const PEDIDOS_LISTADO_LOADING = 'PEDIDOS_LISTADO_LOADING';
export const PEDIDOS_LISTADO_SUCCESS = 'PEDIDOS_LISTADO_SUCCESS';
export const PEDIDOS_LISTADO_FAIL = 'PEDIDOS_LISTADO_FAIL';

export const PEDIDO_CREAR_LOADING = 'PEDIDO_CREAR_LOADING';
export const PEDIDO_CREAR_SUCCESS = 'PEDIDO_CREAR_SUCCESS';
export const PEDIDO_CREAR_FAIL = 'PEDIDO_CREAR_FAIL';

export const PEDIDO_EDITAR_LOADING = 'PEDIDO_EDITAR_LOADING';
export const PEDIDO_EDITAR_SUCCESS = 'PEDIDO_EDITAR_SUCCESS';
export const PEDIDO_EDITAR_FAIL = 'PEDIDO_EDITAR_FAIL';

export const PEDIDOS_REPORTE_FAIL = 'PEDIDOS_REPORTE_FAIL';
export const PEDIDOS_REPORTE_LOADING = 'PEDIDOS_REPORTE_LOADING';
export const PEDIDOS_REPORTE_SUCCESS = 'PEDIDOS_REPORTE_SUCCESS';


//DBS
export const DBS_LISTADO_LOADING = 'DBS_LISTADO_LOADING';
export const DBS_LISTADO_SUCCESS = 'DBS_LISTADO_SUCCESS';
export const DBS_LISTADO_FAIL = 'DBS_LISTADO_FAIL';

export const DB_CREAR_LOADING = 'DB_CREAR_LOADING';
export const DB_CREAR_SUCCESS = 'DB_CREAR_SUCCESS';
export const DB_CREAR_FAIL = 'DB_CREAR_FAIL';

export const DB_EDITAR_LOADING = 'DB_EDITAR_LOADING';
export const DB_EDITAR_SUCCESS = 'DB_EDITAR_SUCCESS';
export const DB_EDITAR_FAIL = 'DB_EDITAR_FAIL';

export const DB_ELIMINAR_LOADING = 'DB_ELIMINAR_LOADING';
export const DB_ELIMINAR_SUCCESS = 'DB_ELIMINAR_SUCCESS';
export const DB_ELIMINAR_FAIL = 'DB_ELIMINAR_FAIL';


//ANULADOS
export const ANULADOS_LISTADO_LOADING = 'ANULADOS_LISTADO_LOADING';
export const ANULADOS_LISTADO_SUCCESS = 'ANULADOS_LISTADO_SUCCESS';
export const ANULADOS_LISTADO_FAIL = 'ANULADOS_LISTADO_FAIL';

export const ANULADO_CREAR_LOADING = 'ANULADO_CREAR_LOADING';
export const ANULADO_CREAR_SUCCESS = 'ANULADO_CREAR_SUCCESS';
export const ANULADO_CREAR_FAIL = 'ANULADO_CREAR_FAIL';

export const ANULADO_EDITAR_LOADING = 'ANULADO_EDITAR_LOADING';
export const ANULADO_EDITAR_SUCCESS = 'ANULADO_EDITAR_SUCCESS';
export const ANULADO_EDITAR_FAIL = 'ANULADO_EDITAR_FAIL';

export const ANULADO_ELIMINAR_LOADING = 'ANULADO_ELIMINAR_LOADING';
export const ANULADO_ELIMINAR_SUCCESS = 'ANULADO_ELIMINAR_SUCCESS';
export const ANULADO_ELIMINAR_FAIL = 'ANULADO_ELIMINAR_FAIL';

//MOVILES
export const MOBILES_LISTADO_LOADING = 'MOBILES_LISTADO_LOADING';
export const MOBILES_LISTADO_SUCCESS = 'MOBILES_LISTADO_SUCCESS';
export const MOBILES_LISTADO_FAIL = 'MOBILES_LISTADO_FAIL';

export const MOBILE_CREAR_LOADING = 'MOBILE_CREAR_LOADING';
export const MOBILE_CREAR_SUCCESS = 'MOBILE_CREAR_SUCCESS';
export const MOBILE_CREAR_FAIL = 'MOBILE_CREAR_FAIL';

export const MOBILE_EDITAR_LOADING = 'MOBILE_EDITAR_LOADING';
export const MOBILE_EDITAR_SUCCESS = 'MOBILE_EDITAR_SUCCESS';
export const MOBILE_EDITAR_FAIL = 'MOBILE_EDITAR_FAIL';

export const MOBILE_ELIMINAR_LOADING = 'MOBILE_ELIMINAR_LOADING';
export const MOBILE_ELIMINAR_SUCCESS = 'MOBILE_ELIMINAR_SUCCESS';
export const MOBILE_ELIMINAR_FAIL = 'MOBILE_ELIMINAR_FAIL';

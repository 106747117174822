import React, { useState } from 'react';

import { ModalHeader, ModalBody, ModalFooter, FormGroup, Button, Container } from 'reactstrap';

const ModalReport = ({ toggle, fields = [], handleReport }) => {
	const [form, setForm] = useState({});

	const handleSubmit = event => {
		event.preventDefault();
		handleReport(form);
		toggle();
	};

	const handleInput = event => {
		setForm({
			...form,
			[event.target.name]: event.target.value
		})
	};

	return (
		<Container>
			<ModalHeader toggle={toggle}>
				<div>
					<h3>Reporte de Pedidos</h3>
				</div>
			</ModalHeader>
			<ModalBody>
				{fields.map((field, j) => {
					if (field.select) {
						return (
							<FormGroup key={j}>
								<label>{field.label}: </label>
								<select
									className="form-control"
									name={field.key}
									onChange={handleInput}>
									{field.select}
								</select>
							</FormGroup>)
					}
					return (
						<FormGroup key={j}>
							<label>{field.label}:</label>
							<input
								className="form-control"
								name={field.key}
								onChange={handleInput}
								value={form[field.key]}
							/>
						</FormGroup>)
				})}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={handleSubmit}>Exportar</Button>{'  '}
				<Button onClick={toggle}>Cancelar</Button>
			</ModalFooter>
		</Container>
	)
};

export default ModalReport;
import React, { useState } from 'react';

import ModalSaveEdit from './modals/ModalSaveEdit';
import { Button, Modal, Container, Table } from 'reactstrap';

const List = ({ headers, items, handleSaveEdit,handleRescue, handleDelete = false,isOrder=false }) => {
	const [show, setShow] = useState(false);
	const [itemEdit, setItemEdit] = useState(false);
	const handleUpdateItem = (item) => {
		setItemEdit(item);
		setShow(true);
	}
	const handleToggle = () => setShow(!show);

	const handleDeleteItem = (_id) => {
		handleDelete(_id);
	}

	return (
		<Container>
			<Table>
				<thead>
					<tr>
						{headers.map((header) => {
							if (header.visible && header.visible.includes('list')) {
								return <th key={header.label}>{header.label}</th>
							}
							return null
						})
						}
					</tr>
				</thead>
				<tbody>
					{items.map((item, i) =>
						<tr key={i}>
							{headers.map((header, j) => {
								if (header.visible && header.visible.includes('list')) {
									if (header.options) {
										return <td key={i + "_" + j}>{header.options[item[header.key]]}</td>
									}
									return <td key={i + "_" + j}>{item[header.key]}</td>
								}
								return null
							})
							}
							<td>
								<Button color="primary" onClick={() => handleUpdateItem(item)}>Editar</Button>{'  '}
								{handleDelete &&
									<Button color="danger" onClick={() => handleDeleteItem(item._id)}>Eliminar</Button>
								}
							</td>
						</tr>
					)
					}
				</tbody>
			</Table>
			<Modal isOpen={show} toggle={handleToggle}>
				<ModalSaveEdit show={show} toggle={handleToggle} headers={headers} item={itemEdit} handleSaveEdit={handleSaveEdit} handleRescue={handleRescue} isEdit={true} isOrder={isOrder} />
			</Modal>
		</Container>
	);
}

export default List;
import axios from '../../utils/axios_ruc';
import {
	MOBILES_LISTADO_LOADING,
	MOBILES_LISTADO_FAIL,
	MOBILES_LISTADO_SUCCESS,
	MOBILE_CREAR_LOADING,
	MOBILE_CREAR_FAIL,
	MOBILE_CREAR_SUCCESS,
	MOBILE_EDITAR_LOADING,
	MOBILE_EDITAR_FAIL,
	MOBILE_EDITAR_SUCCESS,
	MOBILE_ELIMINAR_LOADING,
	MOBILE_ELIMINAR_FAIL,
	MOBILE_ELIMINAR_SUCCESS
} from './actionTypes';

export const mobilesListadoLoading = () => {
	return {
		type: MOBILES_LISTADO_LOADING
	};
};
export const mobilesListadoFail = () => {
	return {
		type: MOBILES_LISTADO_FAIL
	};
};
export const mobilesListadoSuccess = (data) => {
	return {
		type: MOBILES_LISTADO_SUCCESS,
		...data
	};
};

export const getMobilesListado = (page = 1, filter = {}) => {
	return async (dispatch) => {
		dispatch(mobilesListadoLoading());
		try {
			const { data } = await axios.post('api/mobiles/search', { page, filter });
			dispatch(mobilesListadoSuccess(data));
		} catch (e) {
			dispatch(mobilesListadoFail());
		}
	};
};

export const mobileCrearLoading = () => {
	return {
		type: MOBILE_CREAR_LOADING
	};
};
export const mobileCrearFail = () => {
	return {
		type: MOBILE_CREAR_FAIL
	};
};
export const mobileCrearSuccess = (data) => {
	return {
		type: MOBILE_CREAR_SUCCESS,
		...data
	};
};

export const saveMobileItem = (data) => {
	return async (dispatch) => {
		dispatch(mobileCrearLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.post('api/mobile_create', data_send);
			dispatch(mobileCrearSuccess(data));
			dispatch(getMobilesListado(1));
		} catch (e) {
			dispatch(mobileCrearFail());
		}
	};
};

export const mobileEditarLoading = () => {
	return {
		type: MOBILE_EDITAR_LOADING
	};
};
export const mobileEditarFail = () => {
	return {
		type: MOBILE_EDITAR_FAIL
	};
};
export const mobileEditarSuccess = (data) => {
	return {
		type: MOBILE_EDITAR_SUCCESS,
		...data
	};
};

export const editarMobileItem = (data, filter) => {
	return async (dispatch, getState) => {
		dispatch(mobileEditarLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.put('api/mobile', { _id: _id, data: data_send });
			dispatch(mobileEditarSuccess(data));
			dispatch(getMobilesListado(getState().mobiles.page, filter));
		} catch (e) {
			dispatch(mobileEditarFail());
		}
	};
};

export const mobileEliminarLoading = () => {
	return {
		type: MOBILE_ELIMINAR_LOADING
	};
};
export const mobileEliminarFail = () => {
	return {
		type: MOBILE_ELIMINAR_FAIL
	};
};
export const mobileEliminarSuccess = () => {
	return {
		type: MOBILE_ELIMINAR_SUCCESS
	};
};

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utility';

const initialState = {
	username: false,
	token: null,
	loading: false,
	error: false,
};

const authSuccess = (state, action) => {
	return updateObject(state, {
		username: action.username,
		token: action.token,
		error: null,
		loading: false,
	});
};

const authLogout = (state) => {
	return updateObject(state, {
		username: false,
		token: null,
		error: false,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state, action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state);
		default:
			return state;
	}
};

export default reducer;

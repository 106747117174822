import React, { useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationComponent = ({ handleClick, page, totalPages }) => {
	const [actualPage, setActualPage] = useState(1);

	const arrPages = [];
	const limitPages = 3;
	const initPagination = (actualPage - limitPages <= 0) ? 1 : actualPage - limitPages
	const endPagination = (actualPage + limitPages >= totalPages) ? totalPages : actualPage + limitPages
	for (var i = initPagination; i <= endPagination; i++) {
		arrPages.push(i);
	}

	const handleOnClick = (nextPage) => (e) => {
		e.preventDefault();
		handleClick(nextPage);
		setActualPage(nextPage);
	}

	return (
		<Pagination aria-label="Paginacion">
			{actualPage <= 1
				?
				<PaginationItem disabled>
					<PaginationLink first href="#" onClick={handleOnClick(1)} />
				</PaginationItem>
				:
				<PaginationItem>
					<PaginationLink first href="#" onClick={handleOnClick(1)} />
				</PaginationItem>
			}
			{actualPage <= 1
				?
				<PaginationItem disabled>
					<PaginationLink previous href="#" onClick={handleOnClick(actualPage - 1)} />
				</PaginationItem>
				:
				<PaginationItem>
					<PaginationLink previous href="#" onClick={handleOnClick(actualPage - 1)} />
				</PaginationItem>
			}
			{ arrPages.map((page) => {
				if (actualPage === page) {
					return <PaginationItem key={page} active>
						<PaginationLink href="#" onClick={handleOnClick(page)}>{page}</PaginationLink>
					</PaginationItem>
				} else {
					return <PaginationItem key={page}>
						<PaginationLink href="#" onClick={handleOnClick(page)}>{page}</PaginationLink>
					</PaginationItem>
				}
			})
			}
			{actualPage >= totalPages
				?
				<PaginationItem disabled>
					<PaginationLink next href="#" onClick={handleOnClick(actualPage + 1)} />
				</PaginationItem>
				:
				<PaginationItem>
					<PaginationLink next href="#" onClick={handleOnClick(actualPage + 1)} />
				</PaginationItem>
			}
			{actualPage >= totalPages
				?
				<PaginationItem disabled>
					<PaginationLink last href="#" onClick={handleOnClick(totalPages)} />
				</PaginationItem>
				:
				<PaginationItem>
					<PaginationLink last href="#" onClick={handleOnClick(totalPages)} />
				</PaginationItem>
			}
		</Pagination>
	);
}

export default PaginationComponent;
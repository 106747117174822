import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavbarText } from 'reactstrap'

const Header = () => {
	const { username } = useSelector(({ auth }) => auth);
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<div>
			<Navbar color="light" light expand="md">
				<NavbarBrand href="/">recover</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="mr-auto" navbar>
						<li className="nav-item">
							<Link to="/pedidos" className="nav-link">Pedidos</Link>
						</li>
						<li className="nav-item">
							<Link to="/anulados" className="nav-link">Anulados</Link>
						</li>
						<li className="nav-item">
							<Link to="/dbs" className="nav-link">DBs</Link>
						</li>
						<li className="nav-item">
							<Link to="/mobiles" className="nav-link">Moviles</Link>
						</li>
						<li className="nav-item">
							<Link to="/config" className="nav-link">Configuracion</Link>
						</li>
					</Nav>
					<NavbarText>Conflux</NavbarText>
					<NavbarText>
						{username
							? <Link to="/login" className="nav-link">{username}</Link>
							: <Link to="/login" className="nav-link">Login</Link>
						}
					</NavbarText>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default Header;
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Button, Container, Modal } from 'reactstrap';

import List from '../components/List';
import ModalSaveEdit from '../components/modals/ModalSaveEdit.jsx';
import PaginationComponent from '../components/Pagination';
import Search from '../components/Search.jsx';

import { getAnuladosListado, crearAnuladoItem, editarAnuladoItem } from '../store/actions/anulados.actions'

const Anulados = () => {
	const { loading, data, page, totalPages } = useSelector(({ anulados }) => anulados);
	const dispatch = useDispatch();

	const [showSave, setShowSave] = useState(false);
	const [filter, setFilter] = useState({ state: { $in: ['open'] } });
	const handleToggle = () => setShowSave(!showSave);
	const handleSaveItem = (item) => {
		setShowSave(true);
	}

	useEffect(() => {
		dispatch(getAnuladosListado(1, filter))
	}, [])

	const handleClick = (page) => {
		dispatch(getAnuladosListado(page, filter))
	}

	const handleSave = (form) => {
		dispatch(crearAnuladoItem(form))
	}
	const handleEdit = (form) => {
		dispatch(editarAnuladoItem(form, filter))
	}
	/*const handleDelete = (id) => {
		dispatch(eliminarDBItem(id))
	}*/

	const handleSearch = (filterSearch) => {
		setFilter(filterSearch);
		dispatch(getAnuladosListado(1, filterSearch));
	}

	const headers = [
		{ 'key': '_id', 'label': "ID", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': [] },
		{ 'key': 'company', 'label': "Companía", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': ['list', 'form'] },
		{
			'key': 'type', 'label': "Encontrado en", 'visible': ['list', 'form'],
			'options': { 'partner': 'Odoo Cliente', 'einvoice': 'Facturador/Einvoice' },
		},
		{ 'key': 'number', 'label': "Número", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': ['list', 'form'] },
		{
			'key': 'state', 'label': "Estado", 'visible': ['list', 'form'],
			'options': { 'open': 'Abierto', 'done': 'Hecho' },
		},
	]
	const searchItems = [
		{ 'key': 'company', 'label': 'Companía' },
		{ 'key': 'number', 'label': 'Número' },
		{
			'key': 'state', 'label': 'Estado',
			'defaultValue': 'open',
			'filter': {
				'open': { state: { $in: ['open'] } },
				'done': { state: { $in: ['done'] } },
			},
			'options': { 'open': 'Abierto', 'done': 'Hecho' },
		},
	]

	return (
		<Container>
			<Search searchItems={searchItems} handleSearch={handleSearch} />
			<br />
			<Button onClick={handleSaveItem}>Crear</Button>
			<Modal isOpen={showSave} toggle={handleToggle}>
				<ModalSaveEdit show={showSave} toggle={handleToggle} headers={headers} handleSaveEdit={handleSave} isEdit={false} />
			</Modal>
			{loading && <span>Cargando...</span>}
			<List headers={headers} items={data} handleSaveEdit={handleEdit} />
			<PaginationComponent handleClick={handleClick} page={page} totalPages={totalPages} />
		</Container>
	);
};

export default Anulados;
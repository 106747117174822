import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GoogleLogin } from 'react-google-login';
import { getToken } from '../store/actions/auth.actions';
import { getConfigToken } from '../store/actions/other.actions';
import { logoutAction } from '../store/actions/auth.actions';
import { logoutConfigAction } from '../store/actions/other.actions';

const LoginGoogle = () => {
	const { token } = useSelector(({ auth }) => auth);
	const dispatch = useDispatch();

	const responseGoogle = (response) => {
		const email = response.profileObj.email;
		var res = email.split('@', 2);
		var user = {
			clientId: response.googleId,
			username: response.profileObj.email
		};
		if (res[1] === 'conflux.pe') {
			dispatch(getToken(user));
			dispatch(getConfigToken(user));
		} else {
			alert('Esta cuenta no tiene acceso permitido');
		}
	};

	const closeSession = (event) => {
		dispatch(logoutAction());
		dispatch(logoutConfigAction());
	};

	return (
		<div>
			{token ? (
				<div>
					<h1>Ya iniciaste sesión!</h1>
					<button onClick={closeSession}>Cerrar sesión</button>
				</div>
			) : (
				<div>
					<GoogleLogin
						clientId="483018976255-ujgtef1nn7jov6vqrhrejr7ig5opskrj.apps.googleusercontent.com"
						buttonText="Identificarme con mi correo de Google"
						onSuccess={responseGoogle}
						onFailure={responseGoogle}
						cookiePolicy={'single_host_origin'}
					/>
				</div>
			)}
		</div>
	);
};

export default LoginGoogle;

import axios from '../../utils/axios';
import {
	AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_LOGOUT,
} from './actionTypes';

export const authCheckState = () => {
	return (dispatch) => {
		dispatch(authStart());
		const token = localStorage.getItem('token');
		if (!token) {
			dispatch(logout());
		} else {
			axios({
				url: 'api/auth/verify',
				method: 'POST',
				data: { token }
			})
				.then((response) => {
					dispatch(
						authSuccessAction({
							token,
							...response.data,
						})
					);
				})
				.catch((err) => {
					dispatch(authFail(err));
				});
		}
	};
};

export const logout = () => {
	return {
		type: AUTH_LOGOUT,
	};
};
export const logoutAction = () => {
	return async (dispatch) => {
		localStorage.removeItem('token');
		axios.defaults.headers.common['Authorization'] = null;
		axios.defaults.headers.common.Authorization = null;
		dispatch(logout());
	}
};

export const authStart = () => {
	return {
		type: AUTH_START,
	};
};
export const authFail = () => {
	return {
		type: AUTH_FAIL,
	};
};
export const authSuccess = (data) => {
	return {
		type: AUTH_SUCCESS,
		...data
	};
};
export const authSuccessAction = (data) => {
	return async (dispatch) => {
		localStorage.setItem('token', data.token);
		axios.defaults.headers.common['Authorization'] = data.token;
		axios.defaults.headers.common.Authorization = data.token;
		dispatch(authSuccess(data))
	}
};

export const getToken = (user) => {
	return async (dispatch) => {
		dispatch(authStart());
		try {
			const { data } = await axios.post('api/token', user);
			data.username = user.username
			dispatch(authSuccessAction(data))
		} catch (e) {
			dispatch(authFail());
		}
	};
};
import React, { useState } from 'react';

import { ModalHeader, ModalBody, ModalFooter, FormGroup, Button, Container } from 'reactstrap';

const ModalSaveEdit = ({ show, toggle, headers, item = {}, handleSaveEdit,handleRescue, isEdit, isOrder=false }) => {
	const initialForm = {};
	headers.forEach(header => {
		initialForm[header.key] = item[header.key] || '';
	})
	const [form, setForm] = useState(initialForm);
	const [loading,setLoading] = useState(false)

	const handleSubmit = event => {
		event.preventDefault();
		handleSaveEdit(form);
		toggle();
	};

	const handleRescued = e => {
		e.preventDefault();
		setLoading(true)
		handleRescue(form,() => {
			setLoading(false)
			toggle();
		});
	}

	const handleInput = event => {
		setForm({
			...form,
			[event.target.name]: event.target.value
		})
	};

	return (
		<Container>
			{loading ? 
			<ModalBody><h4>cargando</h4></ModalBody>: <>
				<ModalHeader toggle={toggle}>
				<div>
					{isEdit
						? <h3>Editar Registro</h3>
						: <h3>Crear Registro</h3>
					}
				</div>
			</ModalHeader>
			<ModalBody>
				{headers.map((header, j) => {
					if (header.visible && header.visible.includes('form')) {
						if (header.options) {
							let optionDefault = '';
							const selectOption = Object.keys(header.options).map(function (key) {
								if (optionDefault === '') { optionDefault = key };
								return <option key={key} value={key}>{header.options[key]}</option>
							});
							if (!form[header.key]) {
								form[header.key] = optionDefault;
							}
							return (
								<FormGroup key={j}>
									<label>{header.label}:</label>
									{isEdit
										?
										<select
											className="form-control"
											name={header.key}
											value={form[header.key]}
											{...header.inputSettingsEdit}
											onChange={handleInput}>
											{selectOption}
										</select>
										:
										<select
											className="form-control"
											name={header.key}
											value={form[header.key]}
											onChange={handleInput}>
											{selectOption}
										</select>
									}
								</FormGroup>)
						}
						if (header.textarea) {
							return (
								<FormGroup key={j}>
									<label>{header.label}:</label>
									<textarea
										className="form-control"
										name={header.key}
										onChange={handleInput}
										value={form[header.key]}
									/>
								</FormGroup>)
						}
						return (
							<FormGroup key={j}>
								<label>{header.label}:</label>
								{isEdit
									?
									<input
										className="form-control"
										name={header.key}
										{...header.inputSettingsEdit}
										onChange={handleInput}
										value={form[header.key]}
									/>
									:
									<input
										className="form-control"
										name={header.key}
										onChange={handleInput}
										value={form[header.key]}
									/>
								}
							</FormGroup>)
					}
					return null
				})
				}
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={handleSubmit}>Guardar</Button>{'  '}
				{isOrder && item.state==='rescued' ? <Button color="info" onClick={handleRescued}>Rescatar</Button>:null}{' '}
				<Button onClick={toggle}>Cancelar</Button>
			</ModalFooter>
			</>}
		</Container>
	)
};

export default ModalSaveEdit;
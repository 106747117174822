import React, { useState } from 'react';
import { InputGroup, InputGroupText, InputGroupAddon, Input, Button } from 'reactstrap';

const Search = ({ handleSearch, searchItems, filter = {} }) => {
	const [domain, setDomain] = useState(filter);

	const searchSpace = (event, key) => {
		let domain_tmp = domain;
		domain_tmp[key] = { $regex: event.target.value };
		setDomain(domain_tmp);
	}

	const searchFilter = (event, key) => {
		let value = event.target.value;
		let filter;
		searchItems.forEach(item => {
			if (item.key === key) {
				filter = item.filter[value]
			}
		})
		let domain_tmp = { ...domain, ...filter }
		setDomain(domain_tmp);
	}

	function handleClick(e) {
		e.preventDefault();
		handleSearch(domain);
	}

	return (
		<InputGroup>
			{searchItems.map((item, i) => {
				if (item.options) {
					const selectOption = Object.keys(item.options).map(function (key) {
						return <option key={key} value={key}>{item.options[key]}</option>
					});
					return (
						<InputGroupAddon key={i} addonType="prepend">
							<InputGroupText>{item.label}</InputGroupText>
							<select name={item.key} defaultValue={item.defaultValue} onChange={(e) => searchFilter(e, item.key)}>
								{selectOption}
							</select>
						</InputGroupAddon>)
				}
				return (
					<InputGroupAddon key={i} addonType="prepend">
						<InputGroupText>{item.label}</InputGroupText>
						<Input placeholder="Todos" onChange={(e) => searchSpace(e, item.key)} />
					</InputGroupAddon>)
			})}
			<Button color="success" onClick={handleClick}>Buscar</Button>
		</InputGroup>
	);
};

export default Search;
import axios from '../../utils/axios';
import {
	DBS_LISTADO_LOADING, DBS_LISTADO_FAIL, DBS_LISTADO_SUCCESS,
	DB_CREAR_LOADING, DB_CREAR_FAIL, DB_CREAR_SUCCESS,
	DB_EDITAR_LOADING, DB_EDITAR_FAIL, DB_EDITAR_SUCCESS,
	DB_ELIMINAR_LOADING, DB_ELIMINAR_FAIL, DB_ELIMINAR_SUCCESS,
} from './actionTypes';

export const dbsListadoLoading = () => {
	return {
		type: DBS_LISTADO_LOADING,
	};
};
export const dbsListadoFail = () => {
	return {
		type: DBS_LISTADO_FAIL,
	};
};
export const dbsListadoSuccess = (data) => {
	return {
		type: DBS_LISTADO_SUCCESS,
		...data
	};
};

export const getDBsListado = (page = 1, filter = {}) => {
	return async (dispatch) => {
		dispatch(dbsListadoLoading());
		try {
			const { data } = await axios.post('api/dbs/search', { page, filter });
			dispatch(dbsListadoSuccess(data))
		} catch (e) {
			dispatch(dbsListadoFail());
		}
	};
};


export const dbCrearLoading = () => {
	return {
		type: DB_CREAR_LOADING,
	};
};
export const dbCrearFail = () => {
	return {
		type: DB_CREAR_FAIL,
	};
};
export const dbCrearSuccess = (data) => {
	return {
		type: DB_CREAR_SUCCESS,
		...data
	};
};

export const saveDBItem = (data) => {
	return async (dispatch) => {
		dispatch(dbCrearLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.post('api/db', data_send);
			dispatch(dbCrearSuccess(data))
			dispatch(getDBsListado(1))
		} catch (e) {
			dispatch(dbCrearFail());
		}
	};
}


export const dbEditarLoading = () => {
	return {
		type: DB_EDITAR_LOADING,
	};
};
export const dbEditarFail = () => {
	return {
		type: DB_EDITAR_FAIL,
	};
};
export const dbEditarSuccess = (data) => {
	return {
		type: DB_EDITAR_SUCCESS,
		...data
	};
};

export const editarDBItem = (data, filter) => {
	return async (dispatch, getState) => {
		dispatch(dbEditarLoading());
		try {
			const { _id, ...data_send } = data;
			const response = await axios.put('api/db', { '_id': _id, 'data': data_send });
			dispatch(dbEditarSuccess(data))
			dispatch(getDBsListado(getState().dbs.page, filter))
		} catch (e) {
			dispatch(dbEditarFail());
		}
	};
}


export const dbEliminarLoading = () => {
	return {
		type: DB_ELIMINAR_LOADING,
	};
};
export const dbEliminarFail = () => {
	return {
		type: DB_ELIMINAR_FAIL,
	};
};
export const dbEliminarSuccess = () => {
	return {
		type: DB_ELIMINAR_SUCCESS,
	};
};

export const eliminarDBItem = (_id) => {
	return async (dispatch, getState) => {
		dispatch(dbEliminarLoading());
		try {
			console.log("Falta eliminar", _id);
			//const response = await axios.delete('api/db', { _id, token });
			dispatch(dbEliminarSuccess())
			dispatch(getDBsListado(getState().dbs.page))
		} catch (e) {
			dispatch(dbEliminarFail());
		}
	};
}
import { combineReducers } from 'redux';

import pedidos from './pedidos.listado';
import dbs from './dbs.listado';
import anulados from './anulados.listado';
import auth from './auth';
import other from './other';
import mobiles from './mobiles.listado';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		other,
		mobiles,
		pedidos,
		dbs,
		anulados,
		...asyncReducers
	});

export default createReducer;
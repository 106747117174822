import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utility';

const initialState = {
	data: [],
	page: 1,
	totalPages: 1,
	loading: false,
	error: false,
}

const anuladosListadoLoading = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const anuladosListadoSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		page: action.paging.page,
		totalPages: action.paging.num_pages,
		error: null,
		loading: false,
	});
};

const anuladosListadoFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ANULADOS_LISTADO_LOADING:
			return anuladosListadoLoading(state, action);
		case actionTypes.ANULADOS_LISTADO_FAIL:
			return anuladosListadoFail(state, action);
		case actionTypes.ANULADOS_LISTADO_SUCCESS:
			return anuladosListadoSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Container, Modal } from 'reactstrap';

import List from '../components/List';
import ModalSaveEdit from '../components/modals/ModalSaveEdit';
import ModalReport from '../components/modals/ModalReport';
import PaginationComponent from '../components/Pagination';
import Search from '../components/Search';

import { getPedidosListado, guardarPedidoItem, editarPedidoItem, reportePedidos, rescatarPedidoItem } from '../store/actions/pedidos.actions'

const Pedidos = () => {
	const { loading, data, page, totalPages } = useSelector(({ pedidos }) => pedidos);
	const dispatch = useDispatch();
	const isAuth = useSelector(({auth})=>auth.token!==null)

	const [showSave, setShowSave] = useState(false);
	const [filter, setFilter] = useState({ state: { $in: ['open', 'rescued'] } });
	const handleToggle = () => setShowSave(!showSave);
	const handleSaveItem = (item) => {
		setShowSave(true);
	}

	useEffect(() => {
		console.log({isAuth})
		if(isAuth){
			dispatch(getPedidosListado(1, filter))
		}
	}, [isAuth])

	const handleClick = (page) => {
		dispatch(getPedidosListado(page, filter))
	}

	const handleEdit = (form) => {
		dispatch(editarPedidoItem(form, filter));
	}
	const handleSave = (form) => {
		dispatch(guardarPedidoItem(form));
	}
	const handleRescue = (form,cb) => {
		dispatch(rescatarPedidoItem(form, filter,cb));
	}
	const handleSearch = (filterSearch) => {
		setFilter(filterSearch);
		dispatch(getPedidosListado(1, filterSearch));
	}

	const [showReport, setShowReport] = useState(false);
	const handleToggleReport = () => setShowReport(!showReport);
	const handleReport = (data_send) => {
		dispatch(reportePedidos(data_send));
	}

	const headers = [
		{ 'key': '_id', 'label': "ID", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': [] },
		{ 'key': 'company', 'label': "Companía", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': ['list', 'form'] },
		{ 'key': 'pos_number', 'label': "Número POS", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': ['list', 'form'] },
		{
			'key': 'state', 'label': "Estado", 'visible': ['list', 'form'],
			'options': { 'open': 'Abierto', 'rescued': 'Rescatado', 'done': 'Hecho' },
		},
		{ 'key': 'createdAt', 'label': "Fecha de creación", 'inputSettingsEdit': { "type": "text", "readOnly": true }, 'visible': ['list', 'form'] },
		{ 'key': 'data', 'label': "Data", 'textarea': true, 'inputSettingsEdit': { "type": "text", "readOnly": false }, 'visible': ['form'] },
		{ 'key': 'journal_id', 'label':' Journal ID','inputSettingsEdit': { "type": "text", "readOnly": true },'visible': ['form'] },
		{ 'key': 'number', 'label':' Número','inputSettingsEdit': { "type": "text", "readOnly": true },'visible': ['form'] }
	]
	const searchItems = [
		{ 'key': 'company', 'label': 'Companía' },
		{ 'key': 'pos_number', 'label': 'Número' },
		{
			'key': 'state', 'label': 'Estado',
			'defaultValue': 'no_done',
			'filter': {
				'no_done': { state: { $in: ['open', 'rescued'] } },
				'done': { state: { $in: ['done'] } },
				'all': { state: { $in: ['open', 'rescued', 'done'] } },
			},
			'options': { 'no_done': 'Por rescatar', 'done': 'Hechos', 'all': 'Todos' },
		},
	]
	const reportItems = [
		{ 'key': 'company', 'label': 'Companía' },
		{
			'key': 'state',
			'label': 'Estado',
			'select': <>
				<option value="">Todos</option>
				<option value="open">Abierto</option>
				<option value="rescued">Rescatado</option>
				<option value="done">Hecho</option>
			</>
		},
	]

	return (
		<Container>
			<Search filter={filter} searchItems={searchItems} handleSearch={handleSearch} />
			<br />

			<Button onClick={handleSaveItem}>Crear</Button>{'   '}
			<Modal isOpen={showSave} toggle={handleToggle}>
				<ModalSaveEdit show={showSave} toggle={handleToggle} headers={headers} handleSaveEdit={handleSave} handleRescue={handleRescue} isOrder={true} />
			</Modal>

			<Button outline color="info" onClick={handleToggleReport}>Reporte XLSX</Button>
			<Modal isOpen={showReport} toggle={handleToggleReport}>
				<ModalReport toggle={handleToggleReport} fields={reportItems} handleReport={handleReport} />
			</Modal>

			{loading && <span>Cargando...</span>}
			<List headers={headers} items={data} handleSaveEdit={handleEdit} handleRescue={handleRescue} isOrder={true} />
			<PaginationComponent handleClick={handleClick} page={page} totalPages={totalPages} />
		</Container>
	);
};

export default Pedidos;